body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #282c34;
  font-family: 'Rubik', sans-serif;
  color:#3d3d3d;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, p, ul, li {
  margin:0px;

}

#root{
  width:100%;
 
}

.App {
  background-color: #6a6a6a;
  color:#3d3d3d;
  width:100%;
  height:100%;

  
}

a, a.visited{
  color:#3d3d3d;
}