div.layout{
    background: #e2e2e5;
}

div.layout div.content{
    width:100%;
    max-width:1200px;
    background: transparent;
    margin:auto;
  }

div.layout div.header{
    position:sticky;
    height:60px;
    width:100%;
    background:#131313;
    top:0px;
}

div.layout div.header div.logo img{
    position:absolute;
    width:100%;
    max-width:100px;
    max-height: 100px;
    background: transparent;
    margin:10px;

    

}


div.layout div.banner {
    height:800px;
    background:url(../gfx/hand.jpg) center center;
    background-size: cover;
    border-radius: 30px;
    margin-top:30px;
    box-shadow: 0px 5px 5px 5px rgba(68, 68, 68, 0.04);
}

div.layout div.main{
    background: transparent;
}

div.layout div.main div{
    text-align: center;
}

div.layout div.main div h1{
    margin-top:0px;
    padding-top:30px;
    font-size: calc(2.0vw + 20px)
}

div.layout div.main div h3{
    margin-top:0px;
    padding-top:30px;
    font-size: calc(1.0vw + 20px);
    
}

div.layout div.main div h3.header{
    color:#a3a2a2;
}

div.layout div.main div.section p{
   
    font-size: calc(0.5vw + 20px);
    
}

div.layout div.main div.section{
    
    background: transparent;
    background: #ffffff;
    border-radius: 30px;
    margin-top:30px;
    box-shadow: 0px 5px 5px 5px rgba(68, 68, 68, 0.04);
    
}



div.layout div.main div.section div.sectionContent{
    
    
    margin:0 auto;
    border-radius: 30px;
    text-align: center;
    padding:30px;
    max-width:800px;
    border:1px solid transparent;
    text-align: left;
    
}


div.layout div.main  div#register.section div.textinput{
    background: transparent;
    
    background: black;
    padding:10px;
    margin-top:30px;
    border-radius: 30px;
    text-align: center;

    

}

div.layout div.content div.main div#register.section div.sectionContent div.textinput input{
    width:calc(100% - 10px);
    font-size: calc(1.0vw + 20px);
    border:0px;
    background: transparent;
    color:#ffffff;
    padding-left: 10px;
}

div.layout div.footer{
    min-height:500px;
    background: transparent;
    padding:30px;
    margin:0 auto;
    text-align: center;
    padding:30px;
    max-width:800px;
}

div.layout div.footer img.logo{
    width: 100%;
    max-width:300px;
    padding-top:100px;
    padding-bottom:190px;
    margin: 0 auto;
    display: block;
}

div.layout div.footer img.avatar{
    width:50px;
    margin-top:50px;
    margin-bottom:50px;
}

div.layout div.header div.navigation{
    background:transparent;
    margin-left:120px;
    height:70px;


}
div.layout div.header div.navigation a{
    background: transparent;
    border-radius: 10px;
    padding:10px;
    text-decoration: none;
    margin-right: 10px;
    display: block;
    float:left;
    margin-top:10px;
    font-size:calc(0.9vw + 10px);
    color:white;

}

div.layout div.content div.main div#how.section div.sectionContent img{
    width:30px;
    padding-right:10px;
}

div.layout div.content div.main div#how.section div.sectionContent table tr td {
    vertical-align: top;
    padding-top:20px;
    border:0px solid red;
    padding-bottom:20px;

}

div.layout div.content div.main div#how.section div.sectionContent table  tr td ul{
    padding-top:10px;
    
}

 div.main div#register.section div.sectionContent div.errorMessage, div.main div#register.section div.sectionContent div.message{

    height: 50px;
    background: rgb(177, 5, 5);
    margin: 0 auto;
    margin-top:20px;
    padding-top:20px;
    font-weight: bold;
    font-size:calc(0.9vw + 14px);
    color:white;
   
    border-radius: 30px;
    transition: height 300ms linear 0s;

}

div.main div#register.section div.sectionContent div.message{
    background: rgb(51, 148, 32);
}

div.main div#register.section div.sectionContent div.errorMessageHidden, div.main div#register.section div.sectionContent div.messageHidden{

    height: 0px;
    transition: height 300ms linear 0s;
}

html body div#root div.App div.layout div.content div.main div#cash.section{
    background: rgb(6, 157, 245);
}

div.layout div.content div.main div#cash.section h1, div.layout div.content div.main div#cash.section h3,  div.layout div.content div.main div#cash.section p{
    color:#ffffff;
}


@media (max-width: 768px) {
  
    div.layout div.header div.navigation a{
      background: transparent;
      color:white;
      font-size: calc(1.0vw + 10px);

      padding:0px;
      margin:5px;
      margin-top:20px;
      
    }

    div.layout div.header div.navigation{
        margin-left:60px;
    }

    div.layout div.header div.logo img{
        width:40px;
    }
}
